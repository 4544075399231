.error-container {
  background-color: #fdd;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #f88;
  text-align: center;
  max-width: 500px;
  margin: 20px auto;

  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #d44;
    margin-bottom: 12px;
  }
}

.error-message {
  color: #d66;
  white-space: pre-wrap;
  margin-bottom: 12px;
}

.error-home-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}
